import React, { Suspense, lazy } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { DashboardState } from '../models';
import ContentLayout from '../layouts/ContentLayout';
import ExternalLayout from '../layouts/ExternalLayout';
import { DashboardResource, FeatureFlag, TechLogModule, UserPermission } from '../models/userSettings';
import { AircraftPermission, AircraftResource } from '../models/aircraft';
import { PersonCapabilities } from '../models/people';
import Exception403 from '../pages/403';
import Exception404 from '../pages/404';
import Exception500 from '../pages/500';
import AircraftSettings from '../pages/Aircraft/AircraftSettingsNew';
import Overview from '../pages/Aircraft/Overview';
import DentAndBuckle from '../pages/DentAndBuckle/DentAndBuckle';
import AircraftDefectsList from '../pages/Logs/AircraftDefectsList';
import AircraftDocuments from '../pages/Manage/AircraftDocuments';
import Metrics from '../pages/Metrics/Metrics';
import Trips from '../pages/ManageTrips/Trips';
import OauthCallback from '../pages/OauthCallback';
import FleetLogbook from '../pages/FleetLogbook/FleetLogbook';
import Notes from '../pages/Notes/Notes';
import Workpacks from '../pages/Workpacks/Workpacks';
import IntermittentFaults from '../pages/IntermittentFaults/IntermittentFaults';
import CabinLog from '../pages/CabinLog/CabinLog';
import MaintenanceManagement from '../pages/AircraftMaintenance/MaintenanceManagement';
import Monthly from '../pages/Logbook/Monthly';
import CustomFields from '../pages/CustomFields/CustomFields';
import OperatorFRAT from '../pages/OperatorFRAT/OperatorFRAT';
import DashboardStyleHandler from '../pages/Dashboard/DashboardStyleHandler';
import TOTP from '../pages/TOTP/TOTP';
import { AuthProvider, AircraftAuthProvider } from './AuthProvider';

const AddAircraftDetails = lazy(
  () => import(/* webpackChunkName: "AddAircraftDetails" */ '../pages/AddAircraft/Details'),
);
const AppSettings = lazy(() => import(/* webpackChunkName: "AppSettubgs" */ '../pages/AddAircraft/AppSettings'));
const Totals = lazy(() => import(/* webpackChunkName: "Totals" */ '../pages/AddAircraft/Totals'));
const DefectsList = lazy(() => import(/* webpackChunkName: "DefectsList" */ '../pages/Logs/DefectsList'));
const Documents = lazy(() => import(/* webpackChunkName: "Documents" */ '../pages/Manage/Documents'));
const FlightsList = lazy(() => import(/* webpackChunkName: "FlightsList" */ '../pages/Logs/FlightsList'));
const MaintenanceList = lazy(
  () => import(/* webpackChunkName: "FlightProfile " */ '../pages/Maintenance/MaintenanceList'),
);
const People = lazy(() => import(/* webpackChunkName: "People" */ '../pages/UserManagement/UserManagement'));
const Operations = lazy(() => import(/* webpackChunkName: "Operations" */ '../pages/Dashboard/Operations'));
const Routedashboard = lazy(() => import(/* webpackChunkName: "Routedashboard" */ '../pages/Dashboard/Routedashboard'));
const Fuel = lazy(() => import(/* webpackChunkName: "Fuel" */ '../pages/Receipts/Fuel'));
const Aircraft = lazy(() => import(/* webpackChunkName: "Aircraft" */ '../pages/Manage/Aircraft'));
const AccountSettings = lazy(() => import(/* webpackChunkName: "AccountSettings" */ '../pages/Manage/AccountSettings'));
const Integrations = lazy(() => import(/* webpackChunkName: "Integrations" */ '../pages/Manage/Integrations'));
const OperatorSettingsConfig = lazy(
  () => import(/* webpackChunkName: "OperatorSettingsConfig" */ '../pages/Manage/OperatorSettingsConfig'),
);
const OperatorSettings = lazy(
  () => import(/* webpackChunkName: "OperatorSettings" */ '../pages/Manage/OperatorSettings'),
);
const MXSignoff = lazy(() => import(/* webpackChunkName: "MxSignoff" */ '../pages/External/MXSignoff'));

const Loading: React.FC = () => null;

/**
 * React Context Provider for React Router DOM.
 *
 * Currently handles determines which pages require authentication,
 * which paths are available and the permissions required for each path.
 *
 * @returns {ReactFC} React Functional Component
 */
const RoutesWrapper: React.FC = () => {
  const { pathname } = useLocation();

  const { userSettings } = useSelector((state: DashboardState) => state);

  return (
    <Switch>
      <Route path="/auth/callback">
        <OauthCallback />
      </Route>
      <Route path="/defects/:id/public/:public_key" key={`/defects/:id/public/:public_key_${pathname}`} exact>
        <ExternalLayout>
          <Suspense fallback={<Loading />}>
            <MXSignoff />
          </Suspense>
        </ExternalLayout>
      </Route>
      <Route
        path="/scheduled_mx_items/:id/public/:public_key"
        key={`/scheduled_mx_items/:id/public/:public_key_${pathname}`}
        exact
      >
        <ExternalLayout>
          <Suspense fallback={<Loading />}>
            <MXSignoff />
          </Suspense>
        </ExternalLayout>
      </Route>
      <Route path="/public/exception/404" key={`/public/exception/404_${pathname}`} exact>
        <ExternalLayout>
          <Exception404 />
        </ExternalLayout>
      </Route>
      <Route path="/public/exception/500" key={`/public/exception/500_${pathname}`} exact>
        <ExternalLayout>
          <Exception500 />
        </ExternalLayout>
      </Route>
      <Route path="/workpacks/:id/public/:public_key" key={`/workpacks/:id/public/:public_key_${pathname}`} exact>
        <ExternalLayout>
          <Suspense fallback={<Loading />}>
            <MXSignoff />
          </Suspense>
        </ExternalLayout>
      </Route>
      <Route path="/" key={`/_${pathname}`} exact>
        {/* This path should never be used so we redirect to Dashboard instead :) */}
        <Redirect to="/dashboard" />
      </Route>
      <Route path="/add/aircraft" key={`/add/aircraft_${pathname}`} exact>
        <Redirect to="/add/aircraft/details" />
      </Route>
      <Route path="/add/aircraft/details" key={`/add/aircraft/details_${pathname}`} exact>
        <AuthProvider resourceRequired={DashboardResource.AIRCRAFT} permissionRequired={UserPermission.CREATE}>
          <ContentLayout>
            <Suspense fallback={<Loading />}>
              <AddAircraftDetails />
            </Suspense>
          </ContentLayout>
        </AuthProvider>
      </Route>
      <Route path="/add/aircraft/log" key={`/add/aircraft/log_${pathname}`} exact>
        <AuthProvider resourceRequired={DashboardResource.AIRCRAFT} permissionRequired={UserPermission.CREATE}>
          <ContentLayout>
            <Suspense fallback={<Loading />}>
              <AppSettings />
            </Suspense>
          </ContentLayout>
        </AuthProvider>
      </Route>
      <Route path="/add/aircraft/totals" key={`/add/aircraft/totals_${pathname}`} exact>
        <AuthProvider resourceRequired={DashboardResource.AIRCRAFT} permissionRequired={UserPermission.CREATE}>
          <ContentLayout>
            <Suspense fallback={<Loading />}>
              <Totals />
            </Suspense>
          </ContentLayout>
        </AuthProvider>
      </Route>
      <Route path="/aircraft/:id/overview" key={`/aircraft/:id/overview_${pathname}`} exact>
        <AuthProvider resourceRequired={DashboardResource.AIRCRAFT} permissionRequired={UserPermission.READ}>
          <Overview />
        </AuthProvider>
      </Route>
      <Route path="/aircraft/:id/logbook" key={`/aircraft/:id/logbook_${pathname}`} exact>
        <AircraftAuthProvider resourceRequired={AircraftResource.LOGBOOK} permissionRequired={AircraftPermission.READ}>
          <Monthly />
        </AircraftAuthProvider>
      </Route>
      <Route path="/aircraft/:id/trips" key={`/aircraft/:id/trips_${pathname}`} exact>
        <AircraftAuthProvider resourceRequired={AircraftResource.TRIP} permissionRequired={AircraftPermission.READ}>
          <Trips />
        </AircraftAuthProvider>
      </Route>
      <Route
        path="/aircraft/:id/defects"
        key={`/aircraft/:id/defects_${pathname}`}
        exact
        render={({ location: { pathname: localPathname } }): JSX.Element => {
          return <Redirect to={`${localPathname}/log`} />;
        }}
      />
      <Route path="/aircraft/:id/defects/log" key={`/aircraft/:id/defects/log_${pathname}`} exact>
        <AircraftAuthProvider resourceRequired={AircraftResource.DEFECT} permissionRequired={AircraftPermission.READ}>
          <AircraftDefectsList />
        </AircraftAuthProvider>
      </Route>
      <Route path="/aircraft/:id/maintenance" key={`/aircraft/:id/maintenance_${pathname}`} exact>
        <AircraftAuthProvider
          resourceRequired={AircraftResource.SCHEDULED_MX_ITEM}
          permissionRequired={AircraftPermission.READ}
        >
          <MaintenanceManagement />
        </AircraftAuthProvider>
      </Route>
      <Route path="/aircraft/:id/intermittent_faults" key={`/aircraft/:id/intermittent_faults${pathname}`} exact>
        <AircraftAuthProvider resourceRequired={AircraftResource.DEFECT} permissionRequired={AircraftPermission.READ}>
          <IntermittentFaults />
        </AircraftAuthProvider>
      </Route>
      <Route path="/aircraft/:id/cabin_log" key={`/aircraft/:id/cabin_log${pathname}`} exact>
        <AircraftAuthProvider resourceRequired={AircraftResource.DEFECT} permissionRequired={AircraftPermission.READ}>
          <CabinLog />
        </AircraftAuthProvider>
      </Route>
      <Route path="/aircraft/:id/custom_fields" key={`/aircraft/:id/custom_fields${pathname}`} exact>
        <AircraftAuthProvider
          resourceRequired={AircraftResource.AIRCRAFT}
          permissionRequired={AircraftPermission.READ}
          featureFlag={FeatureFlag.CUSTOMFIELDS}
          onlyAdmin
        >
          <CustomFields />
        </AircraftAuthProvider>
      </Route>
      <Route
        path="/aircraft/:id/workpacks"
        key={`/aircraft/:id/workpacks_${pathname}`}
        exact
        render={({ location: { pathname: localPathname } }): JSX.Element => {
          return <Redirect to={`${localPathname}/log`} />;
        }}
      />
      <Route path="/aircraft/:id/workpacks/log" key={`/aircraft/:id/workpacks/log_${pathname}`} exact>
        <AircraftAuthProvider resourceRequired={AircraftResource.WORKPACK} permissionRequired={AircraftPermission.READ}>
          <Workpacks />
        </AircraftAuthProvider>
      </Route>
      <Route path="/aircraft/:id/documents" key={`/aircraft/:id/documents_${pathname}`} exact>
        <AircraftAuthProvider resourceRequired={AircraftResource.DOCUMENT} permissionRequired={AircraftPermission.READ}>
          <AircraftDocuments />
        </AircraftAuthProvider>
      </Route>
      <Route path="/aircraft/:id/metrics" key={`/aircraft/:id/metrics_${pathname}`} exact>
        <AircraftAuthProvider resourceRequired={AircraftResource.TRIP} permissionRequired={AircraftPermission.READ}>
          <Metrics />
        </AircraftAuthProvider>
      </Route>
      <Route
        path="/aircraft/:id/damage_maps"
        key={`/aircraft/:id/damage_maps_${pathname}`}
        exact
        render={({ location: { pathname: localPathname } }): JSX.Element => {
          return <Redirect to={`${localPathname}/list_view`} />;
        }}
      />
      <Route path="/aircraft/:id/dent_and_buckle" key={`/aircraft/:id/dent_and_buckle_${pathname}`} exact>
        <AircraftAuthProvider
          resourceRequired={AircraftResource.DAMAGE_MAP}
          permissionRequired={AircraftPermission.READ}
        >
          <DentAndBuckle />
        </AircraftAuthProvider>
      </Route>
      <Route path="/aircraft/:id/notes" key={`/aircraft/:id/notes_${pathname}`} exact>
        <AircraftAuthProvider resourceRequired={AircraftResource.AIRCRAFT} permissionRequired={AircraftPermission.READ}>
          <Notes />
        </AircraftAuthProvider>
      </Route>
      <Route path="/aircraft/:id/settings" key={`/aircraft/:id/settings_${pathname}`} exact>
        <AuthProvider resourceRequired={DashboardResource.AIRCRAFT} permissionRequired={UserPermission.READ}>
          <AircraftSettings />
        </AuthProvider>
      </Route>
      <Route path="/dashboard" key={`/dashboard_${pathname}`} exact>
        <AuthProvider resourceRequired={DashboardResource.DASHBOARD} permissionRequired={UserPermission.READ}>
          <ContentLayout>
            <Suspense fallback={<Loading />}>
              <DashboardStyleHandler />
            </Suspense>
          </ContentLayout>
        </AuthProvider>
      </Route>
      <Route path="/exception/403" key={`/exception/403_${pathname}`} exact>
        <ContentLayout>
          <Exception403 />
        </ContentLayout>
      </Route>
      <Route path="/exception/404" key={`/exception/404_${pathname}`} exact>
        <ContentLayout>
          <Exception404 />
        </ContentLayout>
      </Route>
      <Route path="/exception/500" key={`/exception/500_${pathname}`} exact>
        <ContentLayout>
          <Exception500 />
        </ContentLayout>
      </Route>
      <Route path="/logbook/:id" key={`/logbook/:id_${pathname}`} exact>
        <AircraftAuthProvider resourceRequired={AircraftResource.LOGBOOK} permissionRequired={AircraftPermission.READ}>
          <Monthly />
        </AircraftAuthProvider>
      </Route>
      <Route path="/defects" key={`/defects_${pathname}`} exact>
        <AuthProvider resourceRequired={DashboardResource.DEFECT} permissionRequired={UserPermission.READ}>
          <ContentLayout>
            <Suspense fallback={<Loading />}>
              <DefectsList />
            </Suspense>
          </ContentLayout>
        </AuthProvider>
      </Route>
      <Route path="/operations/documents" key={`/operations/documents_${pathname}`} exact>
        <AuthProvider
          resourceRequired={DashboardResource.DOCUMENT}
          permissionRequired={UserPermission.READ}
          featureFlag={FeatureFlag.DOCUMENTS}
        >
          <ContentLayout>
            <Suspense fallback={<Loading />}>
              <Documents />
            </Suspense>
          </ContentLayout>
        </AuthProvider>
      </Route>
      <Route path="/operations/flights" key={`/operations/flights_${pathname}`} exact>
        <AuthProvider resourceRequired={DashboardResource.TRIP} permissionRequired={UserPermission.READ}>
          <ContentLayout>
            <Suspense fallback={<Loading />}>
              <FlightsList />
            </Suspense>
          </ContentLayout>
        </AuthProvider>
      </Route>
      <Route path="/operations/logbook" key={`/operations/logbook_${pathname}`} exact>
        <AuthProvider resourceRequired={DashboardResource.AIRCRAFT} permissionRequired={UserPermission.READ}>
          <ContentLayout>
            <Suspense fallback={<Loading />}>
              <FleetLogbook />
            </Suspense>
          </ContentLayout>
        </AuthProvider>
      </Route>
      <Route path="/maintenance" key={`/maintenance_${pathname}`} exact>
        <AuthProvider resourceRequired={DashboardResource.MX_ITEM} permissionRequired={UserPermission.READ}>
          <ContentLayout>
            <Suspense fallback={<Loading />}>
              <MaintenanceList />
            </Suspense>
          </ContentLayout>
        </AuthProvider>
      </Route>
      <Route path="/people" key={`/people_${pathname}`} exact>
        <AuthProvider resourceRequired={DashboardResource.PEOPLE} permissionRequired={UserPermission.READ}>
          <ContentLayout>
            <Suspense fallback={<Loading />}>
              <People />
            </Suspense>
          </ContentLayout>
        </AuthProvider>
      </Route>
      <Route path="/reports/metrics" key={`/reports/metrics_${pathname}`} exact>
        <AuthProvider
          resourceRequired={DashboardResource.DASHBOARD}
          permissionRequired={UserPermission.READ}
          featureFlag={FeatureFlag.METRICS}
        >
          <ContentLayout>
            <Suspense fallback={<Loading />}>
              <Operations />
            </Suspense>
          </ContentLayout>
        </AuthProvider>
      </Route>
      <Route path="/reports/map" key={`/reports/map_${pathname}`} exact>
        <AuthProvider resourceRequired={DashboardResource.DASHBOARD} permissionRequired={UserPermission.READ}>
          <ContentLayout>
            <Suspense fallback={<Loading />}>
              <Routedashboard />
            </Suspense>
          </ContentLayout>
        </AuthProvider>
      </Route>
      <Route path="/reports/receipts" key={`/reports/receipts_${pathname}`} exact>
        <AuthProvider resourceRequired={DashboardResource.RECEIPT} permissionRequired={UserPermission.READ}>
          <ContentLayout>
            <Suspense fallback={<Loading />}>
              <Fuel />
            </Suspense>
          </ContentLayout>
        </AuthProvider>
      </Route>
      <Route path="/settings/aircraft" key={`/settings/aircraft_${pathname}`} exact>
        <AuthProvider resourceRequired={DashboardResource.AIRCRAFT} permissionRequired={UserPermission.READ}>
          <ContentLayout>
            <Suspense fallback={<Loading />}>
              <Aircraft />
            </Suspense>
          </ContentLayout>
        </AuthProvider>
      </Route>
      <Route path="/settings/account" key={`/settings/account_${pathname}`} exact>
        <AuthProvider resourceRequired={DashboardResource.DASHBOARD} permissionRequired={UserPermission.READ}>
          <ContentLayout>
            <Suspense fallback={<Loading />}>
              <AccountSettings />
            </Suspense>
          </ContentLayout>
        </AuthProvider>
      </Route>
      <Route path="/settings/integrations" key={`/settings/integrations_${pathname}`} exact>
        <AuthProvider resourceRequired={DashboardResource.INTEGRATION} permissionRequired={UserPermission.READ}>
          <ContentLayout>
            <Suspense fallback={<Loading />}>
              <Integrations />
            </Suspense>
          </ContentLayout>
        </AuthProvider>
      </Route>
      <Route path="/frat" key={`/frat_${pathname}`} exact>
        <AuthProvider
          resourceRequired={DashboardResource.AIRCRAFT}
          permissionRequired={UserPermission.READ}
          moduleRequired={TechLogModule.FRAT}
          featureFlag={FeatureFlag.FRAT}
        >
          <ContentLayout>
            <Suspense fallback={<Loading />}>
              <OperatorFRAT />
            </Suspense>
          </ContentLayout>
        </AuthProvider>
      </Route>
      {(userSettings?.details?.people?.some((person) =>
        person?.capability_keys?.some(
          key =>
            key === PersonCapabilities.PDF_TEMPLATE_MANAGEMENT || key === PersonCapabilities.AMEND_TEMPLATES,
        )) ?? false) && <Route path="/settings/operators" key={`/settings/operators_${pathname}`} exact>
        <AuthProvider
          resourceRequired={DashboardResource.INTEGRATION}
          permissionRequired={UserPermission.READ}
          featureFlag={FeatureFlag.SRPVIEW}
        >
          <ContentLayout>
            <Suspense fallback={<Loading />}>
              <OperatorSettings />
            </Suspense>
          </ContentLayout>
        </AuthProvider>
      </Route>}
      <Route path="/settings/operator_settings" key={`/settings/operator_settings_${pathname}`} exact>
        <AuthProvider resourceRequired={DashboardResource.AIRCRAFT} permissionRequired={UserPermission.READ}>
          <ContentLayout>
            <Suspense fallback={<Loading />}>
              <OperatorSettingsConfig />
            </Suspense>
          </ContentLayout>
        </AuthProvider>
      </Route>
      <Route path="/aircraft/:id/totp" key={`/aircraft/:id/totp_${pathname}`} exact>
        <AircraftAuthProvider
          resourceRequired={AircraftResource.AIRCRAFT}
          permissionRequired={AircraftPermission.READ}
        >
          <TOTP />
        </AircraftAuthProvider>
      </Route>
      <Route path="*">
        <Redirect to="/exception/404" />
      </Route>
    </Switch>
  );
};

const RouterProvider: React.SFC = () => (
  <Router>
    <RoutesWrapper />
  </Router>
);

export default RouterProvider;
