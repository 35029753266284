import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { DashboardState } from '../../models';
import { Label, Text } from '../../components/CommonStyledComponents/CommonStyledComponents';
import { stringToTitleCase } from '../../utils/utils';
import ToolTip from '../../components/NewTooltip/NewTooltip';

interface CustomFieldsViewAdditionalValuesProps {
  fieldDisplayArray: string[];
  sectorNameOverride: string;
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-top: 20px;
  @media (max-width: 450px) {
    grid-template-columns: 1fr;
  }
`;

export const formatDisplayText = (value: string): string => {
  if (!value) return '';
  const newValue = stringToTitleCase(value).replace(/_/g, ' ');
  return newValue;
};

const CustomFieldsViewAdditionalValues: React.FC<CustomFieldsViewAdditionalValuesProps> = ({
  fieldDisplayArray,
  sectorNameOverride,
}) => {
  const {
    aircraft: { aircraftMap },
    drawer: { drawerId },
  } = useSelector((state: DashboardState) => state);

  const { id } = useParams<{ id: string }>();
  const { formatMessage } = useIntl();

  const foundAircraft = aircraftMap.get(id);
  const customFieldsArray: any[] = foundAircraft ? Object.values(foundAircraft?.custom_fields) : [];

  const foundCustomField = customFieldsArray?.find((field) => field?.key === drawerId);

  return (
    <Wrapper data-testid="CustomFieldsViewAdditionalValues--Wrapper">
      {fieldDisplayArray?.includes('unit') && (
        <div data-testid="CustomFieldsViewAdditionalValues--Unit">
          <Label>
            {formatMessage({ id: 'text.unit' })}
            <ToolTip text={formatMessage({ id: 'text.displayRightOfTheField' })} />
          </Label>
          <Text>{foundCustomField?.unit}</Text>
        </div>
      )}
      <div data-testid="CustomFieldsViewAdditionalValues--ShowOnly">
        <Label>
          {formatMessage({ id: 'text.showOnly' })}
          <ToolTip text={formatMessage({ id: 'text.showOnlyTooltip' })} />
        </Label>
        <Text>{foundCustomField?.show_only?.map((val: string) => formatDisplayText(val))?.join(', ')}</Text>
      </div>
      {fieldDisplayArray?.includes('mode') && (
        <div data-testid="CustomFieldsViewAdditionalValues--Mode">
          <Label>{formatMessage({ id: 'text.mode' })}</Label>
          <Text>{formatDisplayText(foundCustomField?.mode)}</Text>
        </div>
      )}
      {fieldDisplayArray?.includes('default') && (
        <div data-testid="CustomFieldsViewAdditionalValues--Default">
          <Label>
            {formatMessage({ id: 'text.default' })}
            <ToolTip text={formatMessage({ id: 'text.valueShownInBoxWhenFieldLoaded' })} />
          </Label>
          <Text>{foundCustomField?.default}</Text>
        </div>
      )}
      {fieldDisplayArray?.includes('interval') && (
        <div data-testid="CustomFieldsViewAdditionalValues--Interval">
          <Label>{formatMessage({ id: 'text.interval' })}</Label>
          <Text>{foundCustomField?.interval}</Text>
        </div>
      )}
      {fieldDisplayArray?.includes('display_previous_value') && (
        <div data-testid="CustomFieldsViewAdditionalValues--DisplayPreviousValue">
          <Label>
            {formatMessage({ id: 'text.displayPreviousValue' })}
            <ToolTip
              text={formatMessage(
                { id: 'text.displaysDataEnteredOnPreviousFlight' },
                { sector: sectorNameOverride.toLowerCase() },
              )}
            />
          </Label>
          <Text>{foundCustomField?.display_previous_value ? 'Yes' : 'No'}</Text>
        </div>
      )}
      {foundCustomField?.type === 'date' &&
        (foundCustomField?.view === 'preflight' || foundCustomField?.view === 'postflight') && (
          <div data-testid="CustomFieldsViewAdditionalValues--ShowLastTimeCompletedOnPreflight">
            <Label>{formatMessage({ id: 'text.showLastTimeCompletedOnPreflight' })}</Label>
            <Text>{foundCustomField?.show_last_time_completed_on_preflight ? 'Yes' : 'No'}</Text>
          </div>
        )}
    </Wrapper>
  );
};

export default CustomFieldsViewAdditionalValues;
