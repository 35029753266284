import { ReactNode } from 'react';
import { Defect, DefectsActionTypes } from '../defects';
import { IntermittentFault } from '../intermittentFaults';
import { PeopleActionTypes } from '../people';
import { CabinIssuesActionTypes } from '../cabinIssues';

export enum DrawerActionTypes {
  SET_DRAWER_VISIBILITY = 'drawer/setDrawerVisibility',
  SET_DRAWER_CONTENT = 'drawer/setDrawerContent',
  SET_DRAWER_MODE = 'drawer/setDrawerMode',
  HANDLE_BACK_BUTTON = 'drawer/handleBackButton',
  UPDATE_CONTENT = 'drawer/updateContent',
  SET_DRAWER_ID = 'drawer/setDrawerId',
  SET_DRAWER_CHANGES = 'drawer/setDrawerChanges',
  REVERT_DRAWER_CHANGES = 'drawer/revertDrawerChanges',
  SET_MODAL_VISIBILITY = 'drawer/setModalVisibility',
  SET_MODAL_CONTENT = 'drawer/setModalContent',
  REVERT_CHANGES = 'drawer/revertChanges',
  SET_PREVIOUS_DRAWER_CONTENT = 'drawer/setPreviousDrawerContent',
  SET_PASSED_DATA = 'drawer/setPassedData',
  SET_CLOSE_DRAWER_DATA = 'drawer/setCloseDrawerFunction',
}

interface SetDrawerVisibilityActionType {
  type: DrawerActionTypes.SET_DRAWER_VISIBILITY;
  payload: boolean;
}

interface SetDrawerContentActionType {
  type: DrawerActionTypes.SET_DRAWER_CONTENT;
  payload: { content: ReactNode; backButton: boolean; previousContent: any };
}

interface SetDrawerModeActionType {
  type: DrawerActionTypes.SET_DRAWER_MODE;
  payload: string;
}

interface HandleBackButtonActionType {
  type: DrawerActionTypes.HANDLE_BACK_BUTTON;
  payload?: { type: string; content: Defect | IntermittentFault };
}

interface UpdateContentActionType {
  type: DrawerActionTypes.UPDATE_CONTENT;
  payload: boolean;
}

interface SetDrawerIdActionType {
  type: DrawerActionTypes.SET_DRAWER_ID;
  payload: string;
}

interface SetDrawerChangesActionType {
  type: DrawerActionTypes.SET_DRAWER_CHANGES;
  payload: boolean;
}

interface SetModalChangesActionType {
  type: DrawerActionTypes.SET_MODAL_VISIBILITY;
  payload: boolean;
}

interface SetModalContentActionType {
  type: DrawerActionTypes.SET_MODAL_CONTENT;
  payload: ModalContent;
}

interface RevertDrawerChangesActionType {
  type: DrawerActionTypes.REVERT_DRAWER_CHANGES;
}

interface RevertChangesActionType {
  type: DrawerActionTypes.REVERT_CHANGES;
  payload: boolean;
}

interface SetPreviousDrawerContent {
  type: DrawerActionTypes.SET_PREVIOUS_DRAWER_CONTENT;
  payload: any;
}

interface SetPassedData {
  type: DrawerActionTypes.SET_PASSED_DATA;
  payload: any;
}

interface SetCloseDrawerData {
  type: DrawerActionTypes.SET_CLOSE_DRAWER_DATA;
  payload: any;
}

type DrawerAction =
  | SetDrawerContentActionType
  | SetDrawerVisibilityActionType
  | SetDrawerModeActionType
  | UpdateContentActionType
  | SetDrawerIdActionType
  | SetDrawerChangesActionType
  | SetModalChangesActionType
  | SetModalContentActionType
  | RevertDrawerChangesActionType
  | RevertChangesActionType
  | SetPreviousDrawerContent
  | SetPassedData
  | SetCloseDrawerData
  | HandleBackButtonActionType;

export const initialState: DrawerState = {
  visible: false,
  drawerContent: null,
  mode: 'view',
  drawerHistory: [],
  previousDrawerStates: [],
  updateContent: false,
  drawerId: '',
  drawerChanges: false,
  changeModalVisible: false,
  modalContent: null,
  drawerOriginalContent: null,
  revertChanges: null,
  passedData: null,
  previousDrawerContent: [],
  closeDrawerData: null,
  editResolved: false,
};

export const changeDrawerVisibility =
  ({ payload }: { payload: any }) =>
  (dispatch: (args: any) => any): void => {
    dispatch({
      type: DrawerActionTypes.SET_DRAWER_VISIBILITY,
      payload,
    });
    dispatch({
      type: DefectsActionTypes.SAVE_DEFECTS_DRAWER,
      payload: [null],
    });
    dispatch({
      type: PeopleActionTypes.SAVE_SINGLE,
      payload: null,
    });
    if (!payload) {
      dispatch({
        type: CabinIssuesActionTypes.SELECTED_ISSUE_LOCATION,
        payload: null,
      });
    }
  };

export const changeDrawerContent =
  ({ payload }: { payload: any }) =>
  (dispatch: (args: any) => any): void => {
    dispatch({
      type: DrawerActionTypes.SET_DRAWER_CONTENT,
      payload,
    });
  };

export const changeDrawerMode =
  ({ payload }: { payload: any }) =>
  (dispatch: (args: any) => any): void => {
    dispatch({
      type: DrawerActionTypes.SET_DRAWER_MODE,
      payload,
    });
  };

export const handleBackButtonClick =
  () =>
  (dispatch: (args: any) => any): void => {
    dispatch({
      type: DrawerActionTypes.HANDLE_BACK_BUTTON,
    });
  };

export const handleBackButtonClickWithPayload =
  ({ payload }: { payload?: any }) =>
  (dispatch: (args: any) => any): void => {
    dispatch({
      type: DrawerActionTypes.HANDLE_BACK_BUTTON,
      payload,
    });
  };

export const updateDrawerContent =
  ({ payload }: { payload: any }) =>
  (dispatch: (args: any) => any): void => {
    dispatch({
      type: DrawerActionTypes.UPDATE_CONTENT,
      payload,
    });
  };

export const setDrawerId =
  ({ payload }: { payload: any }) =>
  (dispatch: (args: any) => any): void => {
    dispatch({
      type: DrawerActionTypes.SET_DRAWER_ID,
      payload,
    });
  };

export const setDrawerChanges =
  ({ payload }: { payload: any }) =>
  (dispatch: (args: any) => any): void => {
    dispatch({
      type: DrawerActionTypes.SET_DRAWER_CHANGES,
      payload,
    });
  };

export const changeModalVisibility =
  ({ payload }: { payload: boolean }) =>
  (dispatch: (args: any) => any): void => {
    dispatch({
      type: DrawerActionTypes.SET_MODAL_VISIBILITY,
      payload,
    });
  };

export const changeModalContent =
  ({ payload }: { payload: any }) =>
  (dispatch: (args: any) => any): void => {
    dispatch({
      type: DrawerActionTypes.SET_MODAL_CONTENT,
      payload,
    });
  };

export const revertDrawerChanges =
  () =>
  (dispatch: (args: any) => any): void => {
    dispatch({
      type: DrawerActionTypes.REVERT_DRAWER_CHANGES,
    });
  };

export const revertChanges =
  ({ payload }: { payload: any }) =>
  (dispatch: (args: any) => any): void => {
    dispatch({
      type: DrawerActionTypes.REVERT_CHANGES,
      payload,
    });
  };

export const setPreviousDrawerContent =
  ({ payload }: { payload: any }) =>
  (dispatch: (args: any) => any): void => {
    dispatch({
      type: DrawerActionTypes.SET_PREVIOUS_DRAWER_CONTENT,
      payload,
    });
  };

export const setPassedData =
  ({ payload }: { payload: any }) =>
  (dispatch: (args: any) => any): void => {
    dispatch({
      type: DrawerActionTypes.SET_PASSED_DATA,
      payload,
    });
  };

export const setCloseDrawerData =
  ({ payload }: { payload: any }) =>
  (dispatch: (args: any) => any): void => {
    dispatch({
      type: DrawerActionTypes.SET_CLOSE_DRAWER_DATA,
      payload,
    });
  };

export default function (state: DrawerState = initialState, action: DrawerAction): DrawerState {
  switch (action.type) {
    case DrawerActionTypes.SET_DRAWER_VISIBILITY:
      return {
        ...state,
        visible: action.payload,
        drawerContent: null,
        drawerHistory: [],
        updateContent: false,
        drawerId: '',
        drawerChanges: false,
        revertChanges: null,
        passedData: null,
        previousDrawerContent: [],
        closeDrawerData: null,
        editResolved: false,
        mode: 'view',
      };
    case DrawerActionTypes.SET_DRAWER_CONTENT: {
      const newArray = state.drawerHistory;
      const drawerStateArray = state.previousDrawerStates;
      const newContentArray = state.previousDrawerContent;
      if (action.payload.backButton) {
        newArray.push(state.drawerContent);
        drawerStateArray.push(state.mode);
      }
      if (action.payload.previousContent) {
        newContentArray.push(action.payload.previousContent);
      }
      return {
        ...state,
        drawerContent: action.payload.content,
        drawerHistory: newArray,
        drawerChanges: false,
        drawerOriginalContent: action.payload.content,
        previousDrawerContent: newContentArray,
      };
    }
    case DrawerActionTypes.SET_DRAWER_MODE: {
      if (action.payload === 'editResolved') {
        return {
          ...state,
          mode: 'view',
          editResolved: true,
        };
      }
      return {
        ...state,
        mode: action.payload,
        editResolved: false,
      };
    }
    case DrawerActionTypes.HANDLE_BACK_BUTTON: {
      const history = state.drawerHistory;
      const newContent = history.pop();
      const drawerState = state.previousDrawerStates;
      const prevState = drawerState.pop();
      return {
        ...state,
        drawerContent: newContent,
        drawerHistory: history,
        mode: prevState,
        drawerChanges: false,
        drawerOriginalContent: newContent,
        passedData: action?.payload || null,
      };
    }
    case DrawerActionTypes.UPDATE_CONTENT: {
      return {
        ...state,
        updateContent: action.payload,
      };
    }
    case DrawerActionTypes.SET_DRAWER_ID: {
      return {
        ...state,
        drawerId: action.payload,
      };
    }
    case DrawerActionTypes.SET_DRAWER_CHANGES: {
      return {
        ...state,
        drawerChanges: action.payload,
      };
    }
    case DrawerActionTypes.SET_MODAL_VISIBILITY: {
      return {
        ...state,
        changeModalVisible: action.payload,
        modalContent: null,
      };
    }
    case DrawerActionTypes.SET_MODAL_CONTENT: {
      return {
        ...state,
        modalContent: action.payload,
      };
    }
    case DrawerActionTypes.REVERT_DRAWER_CHANGES: {
      return {
        ...state,
        drawerContent: state.drawerOriginalContent,
        changeModalVisible: false,
        drawerChanges: false,
        revertChanges: true,
      };
    }
    case DrawerActionTypes.REVERT_CHANGES: {
      return {
        ...state,
        revertChanges: action.payload,
      };
    }
    case DrawerActionTypes.SET_PREVIOUS_DRAWER_CONTENT: {
      return {
        ...state,
        previousDrawerContent: action.payload,
      };
    }
    case DrawerActionTypes.SET_PASSED_DATA: {
      return {
        ...state,
        passedData: action.payload,
      };
    }
    case DrawerActionTypes.SET_CLOSE_DRAWER_DATA: {
      return {
        ...state,
        closeDrawerData: action.payload,
      };
    }
    default:
      return state;
  }
}

export interface DrawerState {
  visible: boolean;
  drawerContent: ReactNode;
  mode: string;
  drawerHistory: ReactNode[];
  previousDrawerStates: string[];
  updateContent: boolean;
  drawerId: string;
  drawerChanges: boolean;
  drawerOriginalContent: ReactNode;
  changeModalVisible: boolean;
  modalContent: ModalContent;
  revertChanges: boolean;
  passedData: { type: string; content: Defect | IntermittentFault };
  previousDrawerContent: any[];
  closeDrawerData: any;
  editResolved: boolean;
}

interface ModalContent {
  title: string;
  text: string;
  saveButtonText?: string;
  cancelButtonText?: string;
  saveAction?: () => void;
  cancelAction?: () => void;
  hideCancel?: boolean;
}

export enum DrawerMode {
  ADD = 'add',
  EDIT = 'edit',
  VIEW = 'view',
}
