import React from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import TFInput from '../../components/TFInput/TFInput';
import TFNumberInput from '../../components/TFNumberInput/TFNumberInput';
import TFRadioInput from '../../components/TFRadioInput/TFRadioInput';
import TFCheckboxInput from '../../components/TFCheckboxInput/TFCheckboxInput';

interface CustomFieldsEditAdditionalValuesProps {
  customFieldsData: any;
  updateCustomFieldsData: (changes: { value: any; key: string }[]) => void;
  fieldDisplayArray: string[];
  sectorNameOverride: string;
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-top: 20px;
  @media (max-width: 450px) {
    grid-template-columns: 1fr;
  }
`;

const ItemWrapper = styled.div`
  grid-column: span 2;
`;

const CustomFieldsEditAdditionalValues: React.FC<CustomFieldsEditAdditionalValuesProps> = ({
  customFieldsData,
  updateCustomFieldsData,
  fieldDisplayArray,
  sectorNameOverride,
}) => {
  const { formatMessage } = useIntl();
  const handleDisplayPreviousValueChange = (value: boolean | string): void => {
    updateCustomFieldsData([{ key: 'display_previous_value', value }]);
  };

  const handleModeChange = (value: string): void => {
    updateCustomFieldsData([{ key: 'mode', value }]);
  };

  const handleShowOnlyChange = (value: string): void => {
    const newValues = [...(customFieldsData?.show_only || [])];
    const index = newValues.indexOf(value);
    if (index > -1) {
      newValues.splice(index, 1);
    } else {
      newValues.push(value);
    }
    updateCustomFieldsData([{ key: 'show_only', value: newValues }]);
  };

  const handleShowLastOnPreflight = (value: boolean | string): void => {
    updateCustomFieldsData([{ key: 'show_last_time_completed_on_preflight', value }]);
  };

  return (
    <Wrapper data-testid="CustomFieldsEditAdditionalValues--Wrapper">
      {fieldDisplayArray?.includes('unit') && (
        <TFInput
          id="AdditionalValues-Unit"
          value={customFieldsData?.unit}
          onChange={(e): void => updateCustomFieldsData([{ key: 'unit', value: e.target.value }])}
          label="Unit"
          tooltip={formatMessage({ id: 'text.displayRightOfTheField' })}
          optional
        />
      )}
      <TFCheckboxInput
        options={[
          { title: 'Daily first', value: 'daily_first' },
          { title: 'Trip first', value: 'trip_first' },
        ]}
        onChange={handleShowOnlyChange}
        label="Show only"
        optional
        id="AdditionalValues-ShowOnly"
        tooltip={formatMessage({ id: 'text.showOnlyTooltip' })}
        value={customFieldsData?.show_only || []}
      />
      {fieldDisplayArray?.includes('mode') && (
        <ItemWrapper>
          <TFRadioInput
            options={[
              { title: 'Time', value: 'time' },
              { title: 'Date', value: 'date' },
              { title: 'Date time', value: 'date_time' },
            ]}
            onChange={handleModeChange}
            label="Mode"
            id="AdditionalValues-Mode"
            value={customFieldsData?.mode || 'date'}
          />
        </ItemWrapper>
      )}
      {fieldDisplayArray?.includes('default') && (
        <TFInput
          id="AdditionalValues-Default"
          value={customFieldsData?.default}
          onChange={(e): void => updateCustomFieldsData([{ key: 'default', value: e.target.value }])}
          label="Default"
          tooltip={formatMessage({ id: 'text.valueShownInBoxWhenFieldLoaded' })}
          optional
        />
      )}
      {fieldDisplayArray?.includes('interval') && (
        <TFNumberInput
          id="AdditionalValues-Interval"
          value={customFieldsData?.interval}
          onChange={(e): void => updateCustomFieldsData([{ key: 'interval', value: e }])}
          label="Interval"
          optional
        />
      )}
      {fieldDisplayArray?.includes('display_previous_value') && (
        <TFRadioInput
          id="AdditionalValues-DisplayPreviousValue"
          options={[
            { title: 'Yes', value: true },
            { title: 'No', value: false },
          ]}
          value={customFieldsData?.display_previous_value}
          onChange={(value): void => handleDisplayPreviousValueChange(value)}
          label="Display previous value"
          tooltip={formatMessage(
            { id: 'text.displaysDataEnteredOnPreviousFlight' },
            { sector: sectorNameOverride.toLowerCase() },
          )}
          optional
        />
      )}
      {customFieldsData?.type === 'date' &&
        (customFieldsData?.view === 'preflight' || customFieldsData?.view === 'postflight') && (
          <TFRadioInput
            id="AdditionalValues-ShowLastTimeCompletedOnPreflight"
            options={[
              { title: 'Yes', value: true },
              { title: 'No', value: false },
            ]}
            value={customFieldsData?.show_last_time_completed_on_preflight || false}
            onChange={(value): void => handleShowLastOnPreflight(value)}
            label="Show last time completed on preflight"
            optional
          />
        )}
    </Wrapper>
  );
};

export default CustomFieldsEditAdditionalValues;
